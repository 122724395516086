<template>
  <div ref="rootEl" class="form-outer">
    <Transition mode="out-in" @leave="onFormLeave" @enter="onSuccessEnter">
      <div
        v-if="successMessage"
        class="form-success"
        v-html="successMessage"
      ></div>
      <FormBody
        v-else
        ref="form"
        class="form bg-grey"
        :content="content"
        @submit="onSubmit"
        @submit:fail="onSubmitFail"
        @submit:success="onSubmitSuccess"
      >
        <template #group-title="{ content: titleContent }">
          <div class="form-group__title mb-s" v-html="titleContent"></div>
        </template>
        <template #global-submit>
          <Action
            :content="{
              label: content.settings?.sendButtonLabel,
              tag: 'button',
              modifiers: ['btn'],
            }"
            type="submit"
          />

          <div
            id="turnstile-widget"
            :data-sitekey="turnstileKey"
            data-appearance="interaction-only"
            class="cf-turnstile form__catpcha"
          ></div>
        </template>
      </FormBody>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { FormBody } from '@monofront/bob'
import gsap from 'gsap'
import { onMounted, ref } from 'vue'

import { prefersReducedMotion } from '@/utils/motion'
import { formStart, getUtms, push, pushForm } from '@/utils/tracking'

import type { ContentItemField, FormContent } from '@monofront/bob'
import type { AxiosResponse } from 'axios'
import type { PropType } from 'vue'

interface FormEntry {
  path: string
  id?: string
  name?: string
  value: unknown
}

const props = defineProps({
  content: {
    type: Object as PropType<FormContent>,
    required: true,
  },
  profile: {
    type: String,
    required: false,
    default: undefined,
  },
})

const rootEl = ref<HTMLElement | null>(null)
const form = ref<InstanceType<typeof FormBody> | null>(null)
const successMessage = ref<string | null>(null)
const turnstileKey = import.meta.env.VITE_TURNSTILE_KEY as string
let product: string

// Get utms from local storage and fill hidden fields
const fillUtms = () => {
  const utms = getUtms()

  for (const utm of utms) {
    const item = props.content.fields.find(
      item => (item as ContentItemField<string>).name === utm.name
    )

    if (!item || !form.value) {
      return
    }

    form.value.state[item.uid] = utm.value
  }
}

// Event handling
// Track click on submit button
const onSubmit = (data: FormEntry[]) => {
  // Save form data to send in gtm event
  product =
    (data.find(entry => entry.name === 'PRODUCT')?.value as string) || 'none'

  pushForm('click')
}

// Track server errors in form submissioms
const onSubmitFail = (error: string) => {
  pushForm('error')

  // Legacy tracking
  push({
    eventTmp: 'error',
    eventCategory: 'error',
    eventAction: 'contactForm',
    eventLabel: error,
  })
}

// Hide form and show success message
const onSubmitSuccess = (res: AxiosResponse) => {
  successMessage.value = res.data?.message

  // Track succesful submit
  pushForm('success')

  // Legacy tracking
  push({
    eventTmp: 'contactFormConfirmation',
    eventCategory: 'contactFormConfirmation',
    eventAction: 'contactForm',
    eventLabel: product,
  })
}

const onFormLeave = (el: Element, done: () => void) =>
  gsap.to(el, {
    opacity: 0,
    duration: 0.2,
    onStart: () => {
      const parentEl = document.querySelector('.form-parent')

      parentEl &&
        parentEl.scrollIntoView({
          block: 'start',
          behavior: prefersReducedMotion() ? 'instant' : 'smooth',
        })
    },
    onComplete: () => done(),
  })

const onSuccessEnter = (el: Element) =>
  gsap.from(el, {
    opacity: 0,
    duration: 1,
    delay: 0.5,
  })

onMounted(() => {
  // Start tracking on form
  formStart(rootEl.value?.querySelector('form') as HTMLFormElement)

  // Prefill state
  fillUtms()
})
</script>

<style lang="scss">
.form-success {
  padding: $spacing;
  background: $c-white;
  border-radius: 0.8rem;

  @include mq(l) {
    padding: $spacing * 2;
  }
}

.form__catpcha {
  margin-block: 2rem;
}
</style>
