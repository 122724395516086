<template>
  <div>
    <component
      :is="components[resource.template]"
      :key="route.fullPath"
      :content="content"
    />
  </div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'

import { useResourceStore } from '@/stores/resource'
import Notfound from '@/views/404.vue'
import Companies from '@/views/pages/Companies.vue'
import Contact from '@/views/pages/Contact.vue'
import Default from '@/views/pages/Default.vue'
import PageB from '@/views/pages/Demos.vue'
import Documents from '@/views/pages/Documents.vue'
import Employees from '@/views/pages/Employees.vue'
import Hiring from '@/views/pages/Hiring.vue'
import LandingPage from '@/views/pages/LandingPage.vue'
import Merchants from '@/views/pages/Merchants.vue'
import Press from '@/views/pages/Press.vue'
import Privacy from '@/views/pages/Privacy.vue'
import PageA from '@/views/pages/Resources.vue'
import Simulator from '@/views/pages/Simulator.vue'
import Solution from '@/views/pages/Solution.vue'
import SolutionLu from '@/views/pages/SolutionLu.vue'

import type { Component } from 'vue'

// import PageA from '@/views/pages/A.vue'
// import PageB from '@/views/pages/B.vue'

defineProps({
  content: {
    type: Object,
    required: true,
  },
})

const route = useRoute()
const resource = useResourceStore()

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const components: { [key: string]: Component } = {
  /* eslint-disable quote-props*/
  resources: PageA,
  demos: PageB,
  notfound: Notfound,
  default: Default,
  contact: Contact,
  // TODO: Create templates
  companies: Companies,
  landing: LandingPage,
  hiring: Hiring,
  merchants: Merchants,
  employees: Employees,
  documents: Documents,
  network: Default,
  page: Default,
  press: Press,
  'privacy-policy': Privacy,
  simulator: Simulator,
  solution: Solution,
  'solution-lu': SolutionLu,
  /* eslint-enable quote-props*/
}
</script>
