<template>
  <div class="flexible">
    <template v-for="(cpt, cptIndex) in content" :key="cptIndex">
      <component
        :is="components[cpt.component]"
        v-if="cpt.component && components[cpt.component]"
        :content="cpt.content"
        class="flexible__section__component"
        :class="[cptClass(cpt.component as string) || defaultClasses]"
      />

      <pre v-else>coming soon: {{ cpt.component }}</pre>
    </template>
  </div>
</template>

<script lang="ts">
export interface FlexibleContent {
  content: unknown
  component: string
}
</script>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue'

import type { PropType } from 'vue'

defineProps({
  content: {
    type: Array as PropType<FlexibleContent[]>,
    required: true,
  },
})

const components: Record<string, ReturnType<typeof defineAsyncComponent>> = {
  // eslint-disable-next-line camelcase
  cta_picture_left: defineAsyncComponent(
    () => import('@/components/cta/PictureLeft.vue')
  ),
  // eslint-disable-next-line camelcase
  cta_picture_left_bottom: defineAsyncComponent(
    () => import('@/components/cta/PictureLeftBottom.vue')
  ),
  // eslint-disable-next-line camelcase
  cta_picture_right: defineAsyncComponent(
    () => import('@/components/cta/PictureRight.vue')
  ),
  // eslint-disable-next-line camelcase
  cta_text: defineAsyncComponent(() => import('@/components/cta/Text.vue')),
  duo: defineAsyncComponent(() => import('@/components/duo/Combo.vue')),
  focus: defineAsyncComponent(() => import('@/components/focus/Basic.vue')),
  form: defineAsyncComponent(() => import('@/components/flexible/Form.vue')),
  // eslint-disable-next-line camelcase
  keyfacts_product: defineAsyncComponent(
    () => import('@/components/keyfacts/Product.vue')
  ),
  // eslint-disable-next-line camelcase
  keyfacts_text: defineAsyncComponent(
    () => import('@/components/keyfacts/Text.vue')
  ),
  // eslint-disable-next-line camelcase
  solutions_grid: defineAsyncComponent(
    () => import('@/components/grid/SolutionsV2.vue')
  ),
  // eslint-disable-next-line camelcase
  news_grid: defineAsyncComponent(() => import('@/components/grid/News.vue')),
  // eslint-disable-next-line camelcase
  text_duo: defineAsyncComponent(() => import('@/components/duo/Text.vue')),
  tips: defineAsyncComponent(() => import('@/components/ui/Tips.vue')),
}

const cptClass = (name: string) => {
  const classes: Record<string, string[]> = {}

  return classes[name]
}
const defaultClasses = ['']
</script>

<style lang="scss" scoped>
.index {
  position: relative;
  z-index: 18;
}

.flexible {
  @include mq($until: 'l') {
    margin-top: 0;
  }
}
</style>
